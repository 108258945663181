<template>

  <div>
    <template v-if="!isBooted">
      <AppSkeletonLoader v-if="!$vuetify.display.mobile" :type="skeletonType" />
      <AppSkeletonLoader v-if="$vuetify.display.mobile" type="list-item-two-line" />
    </template>
    <template v-if="isBooted">
      <template v-if="getResults || !showNoResultsSection">
        <template v-if="showResultsCount">
          <div class="mt-2">
            <small class="text-disabled">
              {{ data.total }} itens
            </small>
          </div>
        </template>
        <slot />
        <div>
          <v-pagination v-if="showPagination" v-model="currentPage" class="mt-3" :length="pageCount" :total-visible="6"
            rounded="circle" density="compact" active-color="primary" />
        </div>
      </template>
      <template v-else>
        <NoResults style="margin-top: 120px" />
      </template>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {},
  data: {
    type: Object,
    required: true,
  },
  showResultsCount: {
    type: Boolean,
    default: false,
  },
  showNoResultsSection: {
    type: Boolean,
    default: true,
  },
  isBooted: {
    type: Boolean,
    default: true,
  },
  skeletonType: {
    type: String,
    default: "table-row-divider",
  }
});

const emit = defineEmits(["update:modelValue"]);

const getResults = computed(() => props.data.total > 0);

const pageCount = computed(() => props.data.last_page);

const showPagination = computed(() => props.data.last_page > 1);

const currentPage = computed({
  get: () => props.data.current_page,

  set: (value) => emit("update:modelValue", value),
});
</script>